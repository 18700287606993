/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerifyUserCheckParams { 
    /**
     * Six digit verification code of the user.
     */
    verification_code: string;
    /**
     * The email, phone number, or user id of the user.
     */
    user_contact: string;
    /**
     * The channel of communication of the verification code.
     */
    channel?: VerifyUserCheckParams.ChannelEnum;
}
export namespace VerifyUserCheckParams {
    export type ChannelEnum = 'email' | 'sms' | 'service_code';
    export const ChannelEnum = {
        EMAIL: 'email' as ChannelEnum,
        SMS: 'sms' as ChannelEnum,
        SERVICE_CODE: 'service_code' as ChannelEnum
    };
}


