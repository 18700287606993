/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { PaymentMethod } from './paymentMethod';


export interface AdminExtendedSubscription { 
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. This `metadata` will be copied to the `payment_intent.metadata` field when a payment is attempted.
     */
    metadata?: { [key: string]: string; };
    customer: Customer;
    payment_method: PaymentMethod;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The id of the associated account.
     */
    account_id: string;
    /**
     * Determines the date of the first payment and the day of week/month/year for subsequent payments. If a month doesn\'t have the anchor day, the subscription will be billed on the last day of the month. For example, a monthly subscription starting on January 31 bills on Feb 28/29, then March 31, April 30, etc.
     */
    billing_cycle_anchor: string;
    /**
     * A date in the future at which the subscription will automatically get canceled.
     */
    cancel_at?: string;
    /**
     * If the subscription has been canceled, the date of that cancellation.
     */
    canceled_at?: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: AdminExtendedSubscription.CurrencyEnum;
    /**
     * The id of the customer who owns the subscription.
     */
    customer_id: string;
    /**
     * The number of intervals (specified in the `interval_unit` attribute) between subscription billings. For example, `interval_unit=month` and `interval_count=3` bills every 3 months.
     */
    interval_count: number;
    /**
     * The frequency at which a subscription is billed. One of `week`, `month` or `year`.
     */
    interval_unit: AdminExtendedSubscription.IntervalUnitEnum;
    /**
     * The date at which payment will next be attempted. The value will be `null` when no more payments are to be attempted. Examples: `canceled` and `paused` statuses or `cancel_at` is prior to what would be the next payment date. When a payment fails and the status changes to `past_due` this value can be manually updated to determine the next attempt.
     */
    next_payment_at?: string;
    /**
     * A date in the future at which the subscription will automatically get paused.
     */
    pause_at?: string;
    /**
     * If the subscription has been paused, the date of the most recent pausing.
     */
    paused_at?: string;
    /**
     * The id of the PaymentMethod used for this subscription. It must belong to the customer associated with the subscription.
     */
    payment_method_id: string;
    /**
     * The amount of the fee (if any) that will be requested to be applied to the payment and transferred to the `partner` account. The amount of the fee collected will be capped a the total payment amount.
     */
    platform_fee_amount?: number;
    /**
     * Amount intended to be collected by this subscription. A positive integer representing how much to charge in the smallest currency unit (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency).
     */
    price: number;
    /**
     * If the subscription has been paused, the date in the future at which the subscription will automatically return to active.
     */
    resume_at?: string;
    /**
     * Status of this subscription, one of `active`, `canceled`, `past_due`, `paused`, or `pending`.
     */
    status: AdminExtendedSubscription.StatusEnum;
}
export namespace AdminExtendedSubscription {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type IntervalUnitEnum = 'week' | 'month' | 'year';
    export const IntervalUnitEnum = {
        WEEK: 'week' as IntervalUnitEnum,
        MONTH: 'month' as IntervalUnitEnum,
        YEAR: 'year' as IntervalUnitEnum
    };
    export type StatusEnum = 'active' | 'canceled' | 'past_due' | 'paused' | 'pending';
    export const StatusEnum = {
        ACTIVE: 'active' as StatusEnum,
        CANCELED: 'canceled' as StatusEnum,
        PAST_DUE: 'past_due' as StatusEnum,
        PAUSED: 'paused' as StatusEnum,
        PENDING: 'pending' as StatusEnum
    };
}


