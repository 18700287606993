import {
  AdminGetBankAccountRequestParams,
  AdminGetContentsOfFileRequestParams,
  AdminService,
  ListFilesRequestParams,
  ModelFile,
} from '@admin-api/index';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isRoutingNumber } from '../validators/routing-number.validator';

export interface IReviewBankAccountDialogData {
  accountId: string;
  bankAccountId: string;
}

export interface IReviewBankAccountDialogReturnData {
  accountNumber: string;
  routingNumber: string;
  verifiedBankAccount: boolean;
}

@Component({
  selector: 'app-review-bank-account-dialog',
  templateUrl: './review-bank-account-dialog.component.html',
  styleUrls: ['./review-bank-account-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewBankAccountDialogComponent {
  public accountId: string;
  public bankAccountId: string;
  public currentAccountNumber: string;
  public currentRoutingNumber: string;

  public failedToLoadFile: boolean = false;
  public noFilesUploaded: boolean = false;

  public editBankAccountForm: UntypedFormGroup;
  public bankAccountVerified: boolean = false;
  public currentlySaving: boolean = false;

  public bankDocuments: ModelFile[] = [];
  public documentTypes: string[] = [];
  private objectUrl: string;
  public safeUrl: SafeResourceUrl;
  public mimeType: string;

  constructor(
    public matDialogRef: MatDialogRef<ReviewBankAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: IReviewBankAccountDialogData,
    private readonly _adminService: AdminService,
    private sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
  ) {
    this.matDialogRef.afterClosed().subscribe(() => {
      window.URL.revokeObjectURL(this.objectUrl);
    });

    this.editBankAccountForm = this._formBuilder.group({
      accountNumber: new UntypedFormControl(null),
      routingNumber: new UntypedFormControl(null, [isRoutingNumber()]),
      verifyCheck: new UntypedFormControl(false, [Validators.required]),
    });

    this.accountId = dialogData.accountId;
    this.bankAccountId = dialogData.bankAccountId;

    const requestParameters: AdminGetBankAccountRequestParams = {
      id: this.bankAccountId,
      accountId: this.accountId,
    };

    this._adminService.adminGetBankAccount(requestParameters).subscribe({
      next: (bankAccount) => {
        this.currentAccountNumber = (bankAccount as any).account_number;
        this.currentRoutingNumber = bankAccount.routing_number;
      },
    });

    const requestParams: ListFilesRequestParams = {
      accountId: this.accountId,
      purposes: [ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION as any],
      offset: 0,
      limit: 100,
    };

    this._adminService.listFiles(requestParams).subscribe({
      next: (files) => {
        if (!files || files.total === 0) {
          this.noFilesUploaded = true;
          return;
        }

        for (const doc of files.items) {
          this.bankDocuments.push(doc);
          // business_document title is split with ':'
          this.documentTypes.push(doc.title.split(':')[0]);
        }
        this.getFileData(this.bankDocuments[0]);
      },
      error: (error) => {
        this.failedToLoadFile = true;
      },
      complete: () => {},
    });
  }

  public async getFileData(file: ModelFile): Promise<any> {
    let params: AdminGetContentsOfFileRequestParams = {
      accountId: this.accountId,
      fileId: file.id,
    };

    this._adminService.adminGetContentsOfFile(params).subscribe({
      next: (data) => {
        if (!data) {
          this.failedToLoadFile = true;
          return;
        }
        switch (this.bankDocuments[0].type) {
          case ModelFile.TypeEnum.PDF:
            this.mimeType = 'application/pdf';
            break;
          case ModelFile.TypeEnum.JPG:
            this.mimeType = 'image/jpg';
            break;
          case ModelFile.TypeEnum.PNG:
            this.mimeType = 'image/png';
            break;
          case ModelFile.TypeEnum.TIFF:
            this.mimeType = 'image/tiff';
            break;
          default:
            return;
        }

        const object = new File([data], this.bankDocuments[0].filename, {
          type: this.mimeType,
        });
        this.objectUrl = window.URL.createObjectURL(object);

        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.objectUrl,
        );
        this.failedToLoadFile = false;
        this.noFilesUploaded = false;
      },
      error: (err) => {
        this.failedToLoadFile = true;
      },
    });
  }

  public verifyCheckChanged(event: MatCheckboxChange) {
    this.bankAccountVerified = event.checked;
  }

  public verifyBankAccountClicked() {
    if (this.editBankAccountForm.invalid || !this.bankAccountVerified) {
      return;
    }
    this.currentlySaving = true;

    let updatedAccountNumber = this.editBankAccountForm.get('accountNumber')
      .value as string;
    let updatedRoutingNumber = this.editBankAccountForm.get('routingNumber')
      .value as string;

    if (
      !updatedAccountNumber ||
      updatedAccountNumber === this.currentAccountNumber
    ) {
      updatedAccountNumber = this.currentAccountNumber;
    }
    if (
      !updatedRoutingNumber ||
      updatedRoutingNumber === this.currentRoutingNumber
    ) {
      updatedRoutingNumber = this.currentRoutingNumber;
    }

    let returnData: IReviewBankAccountDialogReturnData = {
      accountNumber: updatedAccountNumber,
      routingNumber: updatedRoutingNumber,
      verifiedBankAccount: true,
    };
    this.matDialogRef.close(returnData);
  }
}
