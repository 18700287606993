/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AgreementFile } from './agreementFile';


export interface Agreement { 
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The id of associated file
     */
    file_id: string;
    file: AgreementFile;
    /**
     * The original of the uploaded document
     */
    name: string;
    /**
     * The type of terms or policy
     */
    type: Agreement.TypeEnum;
    /**
     * Active if it is the current document for that type. Inactive if it is no longer current, because it may be linked to an agreement
     */
    active: object;
    /**
     * ID of the Tilled account for this agreement. If there is no account ID, the agreement will be used for all accounts.
     */
    account_id?: string;
    /**
     * When the agreement is deleted.
     */
    deleted_at?: string;
}
export namespace Agreement {
    export type TypeEnum = 'CA Card Terms and Conditions' | 'CA Portal Terms and Conditions' | 'US Card Terms and Conditions' | 'US Portal Terms and Conditions' | 'ACH Debit Terms and Conditions' | 'Privacy Policy';
    export const TypeEnum = {
        CA_CARD_TERMS_AND_CONDITIONS: 'CA Card Terms and Conditions' as TypeEnum,
        CA_PORTAL_TERMS_AND_CONDITIONS: 'CA Portal Terms and Conditions' as TypeEnum,
        US_CARD_TERMS_AND_CONDITIONS: 'US Card Terms and Conditions' as TypeEnum,
        US_PORTAL_TERMS_AND_CONDITIONS: 'US Portal Terms and Conditions' as TypeEnum,
        ACH_DEBIT_TERMS_AND_CONDITIONS: 'ACH Debit Terms and Conditions' as TypeEnum,
        PRIVACY_POLICY: 'Privacy Policy' as TypeEnum
    };
}


