//For a new demo, need to update values here, and also the primary and accent palettes in tailwind.config.js
export const demo = {
    partnerName: 'Heartland',
    assetsLogoPath: 'assets/images/logo/',
    partnerFaviconAlt: 'Heartland Logo',
    partnerFaviconLogo: 'heartland-logo-favicon.png', //browser tab and loading logo (transparent bg)
    partnerLightLogo: 'heartland-logo-light.png', //light mode logo (dark text, transparent bg)
    partnerDarkLogo: 'heartland-logo-dark.png', //dark mode logo (light text, transparent bg)
  };
  
  document.title = demo.partnerName;
  document.getElementById('logoImg').setAttribute('alt', demo.partnerFaviconAlt);
  document.getElementById('logoImg').setAttribute('src', demo.assetsLogoPath + demo.partnerFaviconLogo);
  document.getElementById('favicon').setAttribute('href', demo.assetsLogoPath + demo.partnerFaviconLogo);