import { AdminPermissionSet } from '@admin-api/index';
import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth/auth.service';
import { INSUFFICIENT_PRIVILEGES } from '../constants';

/**
 * Selector [permission]="'secrets:write'" is used for an attribute directive that
 * effectively disables the element.
 */
@Directive({
  selector: '[permission]',
})
export class AdminPermissionDirective implements OnInit {
  @Input() permission: AdminPermissionSet.PermissionsEnum | undefined;

  /**
   * If true, the view is removed (like *ngIf)
   * If false, then the elements are disabled.
   */
  @Input() removeOnDenied: boolean = false;

  // Allows the setting of the 'disabled' attribute on the element
  @HostBinding('disabled') isDisabled: string = '';
  @HostBinding('value') elementValue: string = '';

  private elementTypesToDisable = ['INPUT', 'SELECT', 'BUTTON', 'TEXTAREA'];

  constructor(
    private _el: ElementRef,
    private _snackBar: MatSnackBar,
    private _authService: AuthService,
  ) {}

  async ngOnInit() {
    this._authService
      .isPermissionAllowed(this.permission)
      .subscribe((isAllowed) => {
        let htmlElement = this._el.nativeElement as HTMLElement;

        if (isAllowed) {
          // Do nothing...
        } else if (this.removeOnDenied) {
          // Remove from DOM
          htmlElement.remove();
        } else {
          // Disable
          if (this.elementTypesToDisable.includes(htmlElement.tagName)) {
            if (htmlElement instanceof HTMLButtonElement) {
              // Setting this.isDisabled *will* disable the element, but then we lose the ability
              // for tooltips or alerts/snackbar messages.
              // this.isDisabled = 'disabled';
              // We will simply remove all listeners and then override the onclick listener.
              htmlElement.removeAllListeners('click');
              htmlElement.onclick = (ev) => {
                // alert(`Requires permission: '${this.permission}'`);
                this._snackBar.open(
                  `Requires permission: '${this.permission}'`,
                  'Understood',
                  {
                    panelClass: ['warn-snackbar'], // found in styles.scss
                  },
                );
              };
            } else if (htmlElement instanceof HTMLInputElement) {
              htmlElement.removeAllListeners();
              this.elementValue = INSUFFICIENT_PRIVILEGES;
              // htmlElement.value = INSUFFICIENT_PRIVILEGES;
              // htmlElement.placeholder = INSUFFICIENT_PRIVILEGES;
              this.isDisabled = 'disabled';
            } else if (htmlElement instanceof HTMLTextAreaElement) {
              htmlElement.removeAllListeners();
              this.elementValue = INSUFFICIENT_PRIVILEGES;
              // htmlElement.value = INSUFFICIENT_PRIVILEGES;
              // htmlElement.placeholder = INSUFFICIENT_PRIVILEGES;
              this.isDisabled = 'disabled';
            }

            // None of these seemed to work at all
            // this._renderer.setProperty(this._el.nativeElement, 'disabled', true);
            // this._el.nativeElement.disabled = true;
            // // HTMLButtonElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
            // // These don't appear to be happening
            // (htmlElement as HTMLButtonElement).disabled = true;
            // htmlElement['disabled'] = 'disabled';
            // // htmlElement.classList.add('disable-me');
            // var tooltipDiv = document.createElement('div');
            // tooltipDiv.innerHTML = htmlElement.outerHTML;
            // htmlElement.parentNode.insertBefore(tooltipDiv, htmlElement);
            // htmlElement.remove();
          }
        }
      });
  }
}
